<i18n>
{
  "de": {
    "pageTitle": "Benutzer erfassen",
    "firstNameLabel": "Vorname",
    "lastNameLabel": "Nachname",
    "companyNameLabel": "Firma",
    "emailLabel": "E-Mail",
    "passwordLabel": "Passwort",
    "submitButtonLabel": "Speichern",
    "errorEmailInvalid": "Ungültige E-Mail-Adresse",
    "errorHeader": "Beim Speichern ist ein Fehler aufgetreten"
  }
}
</i18n>

<template>
  <MainLayout :ready="allUsersReady" class="admin-add-user-page">
    <template #default>
      <h1>{{ $t('pageTitle') }}</h1>

      <!-- Errors -->
      <div v-if="error" class="error-message">
        <h2>{{ $t('errorHeader') }}</h2>
        <p>
          <b>{{ error }}</b>
        </p>
        <ul v-if="errorFields">
          <li v-for="field in errorFields" :key="field.name">{{ field.name }}: {{ field.error }}</li>
        </ul>
      </div>

      <!-- TODO: 
            [ ] refactor using form components?
            [ ] validate email string, password length, empty fields
            [ ] don't disable submit button, validate instead
       -->
      <form>
        <div class="form-item">
          <label for="user-first-name-field">{{ $t('firstNameLabel') }}</label>
          <input id="user-first-name-field" v-model="firstName" type="text" />
        </div>
        <div class="form-item">
          <label for="user-last-name-field">{{ $t('lastNameLabel') }}</label>
          <input id="user-last-name-field" v-model="lastName" type="text" />
        </div>
        <div class="form-item">
          <label for="user-company-name-field">{{ $t('companyNameLabel') }}</label>
          <input id="user-company-name-field" v-model="companyName" type="text" />
        </div>
        <div class="form-item">
          <label for="user-email-field">{{ $t('emailLabel') }}</label>
          <div>
            <input
              id="user-email-field"
              v-model="email"
              type="email"
              :class="{ 'invalid-email': !isEmailValid && hasBeenBlurred }"
              @blur="onEmailBlur"
            />
            <div v-if="!isEmailValid && hasBeenBlurred" class="invalid-message">
              <span>{{ $t('errorEmailInvalid') }}</span>
            </div>
          </div>
        </div>
        <div class="form-item">
          <label for="user-password-field">{{ $t('passwordLabel') }}</label>
          <input id="user-password-field" v-model="password" type="password" />
        </div>

        <ButtonWrapper>
          <button type="button" class="button" :disabled="!isSubmitButtonEnabled" @click="onSubmit()">
            {{ $t('submitButtonLabel') }}
          </button>
        </ButtonWrapper>
      </form>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'
import { isValidEmail } from '@/services/util.js'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

export default {
  name: 'adminAddUser',

  mixins: [
    AdminAllUsersMixin, // Provides: allUsers, allUsersReady
  ],

  components: {
    ButtonWrapper,
    MainLayout,
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      companyName: null,
      email: null,
      password: null,
      error: null,
      isEmailValid: false,
      hasBeenBlurred: false,
    }
  },

  computed: {
    errorFields() {
      let errorFields = []
      if (this.error.message.invalidFields !== undefined) {
        for (const prop in this.error.message.invalidFields) {
          if (Object.prototype.hasOwnProperty.call(this.error.message.invalidFields, prop)) {
            errorFields.push({
              name: prop,
              error: this.error.message.invalidFields[prop],
            })
          }
        }
      }
      return errorFields
    },

    isSubmitButtonEnabled() {
      return Boolean(this.firstName && this.lastName && this.isEmailValid && this.companyName && this.password)
    },
  },

  watch: {
    email() {
      this.isEmailValid = isValidEmail(this.email)
    },
  },

  methods: {
    ...mapActions({
      addUser: 'users/addUser',
    }),

    onEmailBlur() {
      this.hasBeenBlurred = true
    },

    async onSubmit() {
      try {
        this.error = null
        await this.addUser({
          first_name: this.firstName.trim(),
          last_name: this.lastName.trim(),
          company_name: this.companyName.trim(),
          email: this.email.trim(),
          password: this.password,
        })
        this.$router.push({ name: 'adminUsers' })
      } catch (error) {
        this.error = error
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.admin-add-user-page {
  & form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxs);
    width: 400px;

    & .form-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #fff;
      margin-bottom: 0px;
      transition: margin 0.2s ease-in;

      & label {
        flex: none;
        width: 120px;
        display: block;
        font-weight: 500;
        line-height: 28px;
      }

      & > div {
        flex: 1;
      }

      &:has(.invalid-message) {
        margin-bottom: 20px;

        & .invalid-message span {
          opacity: 1;
        }
      }
    }
  }

  & .invalid-message {
    position: relative;
    color: #e11;
    font-size: 15px;
    line-height: 20px;

    & span {
      position: absolute;
      transition: opacity 0.3s ease-in;
      opacity: 0;
    }
  }

  & .error-message {
    position: absolute;
    padding: var(--box-padding);
    border: var(--box-error-border);
    max-width: 800px;

    & h2 {
      color: var(--error-color);
    }
  }

  & input {
    &.invalid-email {
      border-color: #e11 !important;

      &:focus {
        border-color: #e11 !important;
        outline: 3px solid #fdd !important;
      }
    }
  }
}
</style>
