<!--
AdminAllUsersMixin
Loads all users

Provides: allUsers, allUsersReady
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      allUsers: 'users/getAll',
    }),

    allUsersReady() {
      return this.allUsers !== null
    },
  },

  created() {
    if (!this.allUsersReady) {
      this.loadAllUsers()
    }
  },

  methods: {
    ...mapActions({
      loadAllUsers: 'users/loadAll',
    }),
  },
}
</script>
